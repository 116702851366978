import React, { useEffect, useState } from "react";
import { useAuth, useApi } from "../hooks";
import { AuthorizationContext } from "../AuthorizationContext";
import RequestAccessForm, { RequestAccessResult } from "./RequestAccessForm";
import { Feature } from "@timandgareth/domain";
import CircularProgress from "@mui/material/CircularProgress";
import LoginForm from "./LoginForm";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface LoginWrapperProps {
  children: JSX.Element;
}

export default function LoginWrapper({ children }: LoginWrapperProps) {
  const { t } = useTranslation();
  const { createChallenge, attemptChallenge, getFeatures, requestAccess } =
    useApi();
  const { jwt, setJwt, jwtRefreshInProgress } = useAuth();
  const [showRequestAccessForm, setShowRequestAcccessForm] = useState(false);
  const [email, setEmail] = useState<string>();
  const [features, setFeatures] = useState<Feature[]>([]);

  useEffect(() => {
    console.log(
      `LoginWrapper.useEffect(jwt=${jwt}, jwtRefreshInProgress=${jwtRefreshInProgress})`
    );
    if (jwt && !jwtRefreshInProgress) {
      console.log("Requesting enabled features...");
      getFeatures()
        .then(setFeatures)
        .then(() => console.log("Features loaded."))
        .catch((e) => console.error("Failed to load features.", e));
    }
  }, [jwt, jwtRefreshInProgress]);

  async function onEmailSubmit(email: string) {
    const createChallengeResponse = await createChallenge(email);
    setEmail(email);
    switch (createChallengeResponse.status) {
      case "CHALLENGE_RESPONSE_SENT":
        break;
      case "EMAIL_NOT_REGISTERED":
        setShowRequestAcccessForm(true);
        break;
    }
    return createChallengeResponse;
  }

  async function onChallengeAnswerSubmit(
    email: string,
    answer: string,
    includeRefreshToken: boolean
  ) {
    const attemptChallengeResponse = await attemptChallenge(
      email,
      answer,
      includeRefreshToken
    );
    switch (attemptChallengeResponse.status) {
      case "OK":
        setJwt(attemptChallengeResponse.jwt!!);
        break;
    }
    return attemptChallengeResponse;
  }

  async function onRequestAccess(email: string, introduction: string) {
    const requestAccessResponse = await requestAccess(email, introduction);
    if (requestAccessResponse.status === "OK") {
      console.log("Got OK response on request access.");
      return RequestAccessResult.SUCCESS;
    } else {
      return RequestAccessResult.FAILURE;
    }
  }

  function onRequestAccessFormBackClick() {
    setShowRequestAcccessForm(false);
  }

  console.log(`features = [${features.join(", ")}]`);

  if (!jwt) {
    if (jwtRefreshInProgress) {
      return (
        <Grid container>
          <Grid item sx={{ justifyContent: "center" }}>
            <CircularProgress />
            <Typography variant="h3">
              {t("login-wrapper.authenticating")}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (!showRequestAccessForm) {
      return (
        <LoginForm
          onEmailSubmit={onEmailSubmit}
          onChallengeAnswerSubmit={onChallengeAnswerSubmit}
        />
      );
    }
    return (
      <RequestAccessForm
        initialEmail={email}
        onSubmit={onRequestAccess}
        onBackClick={onRequestAccessFormBackClick}
      />
    );
  }

  // TODO handle user not enabled for any features?
  return (
    <AuthorizationContext.Provider value={{ features }}>
      {children}
    </AuthorizationContext.Provider>
  );
}
