import {
  Recipe,
  RequestAccessResponse,
  AllowAccessResponse,
  ContactInfos,
  Video,
  Place,
  AuthorizedRole,
  Feature,
  OpenPhotoAlbum,
  CreateChallengeResponse,
  AttemptChallengeResponse,
  ListPhotoAlbumsResponseV3,
  User,
  TodoList,
} from "@timandgareth/domain";
import { isExpired } from "./../util/authToken";
import { StatusCodes } from "http-status-codes";
import CodedError, { ERROR_CODE_UNAUTHORIZED } from "./../CodedError";
import useAuth from "./useAuth";

declare type JWT = string | undefined;

class AuthTokenMissingOrExpiredError extends Error {
  constructor() {
    super("auth token missing or expired");
  }
}

async function createChallenge(
  email: string
): Promise<CreateChallengeResponse> {
  const response = await fetch(
    process.env.REACT_APP_SERVER_BASE_URL + "/auth/challenge",
    {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      method: "POST",
      body: JSON.stringify({ email }),
    }
  );
  const responseStatus = response.status;
  const responseBody = (await response.json()) as CreateChallengeResponse;
  if (responseStatus < 500) {
    return responseBody;
  } else {
    throw new Error(`Challenge creation failed due to a technical error.`);
  }
}

async function attemptChallenge(
  email: string,
  answer: string,
  includeRefreshToken: boolean
): Promise<AttemptChallengeResponse> {
  const attemptResponse = await fetch(
    process.env.REACT_APP_SERVER_BASE_URL + "/auth/challenge/response",
    {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      method: "POST",
      body: JSON.stringify({ email, answer, includeRefreshToken }),
    }
  );
  const responseStatus = attemptResponse.status;
  const responseBody =
    (await attemptResponse.json()) as AttemptChallengeResponse;
  if (responseStatus < 500) {
    return responseBody;
  } else {
    throw new Error(
      `Challenge response request failed due to a technical error.`
    );
  }
}

async function requestAccess(
  email: string,
  introduction: string
): Promise<RequestAccessResponse> {
  const response = await fetch(
    process.env.REACT_APP_SERVER_BASE_URL + "/auth/access-request",
    {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      method: "POST",
      body: JSON.stringify({ email, introduction }),
    }
  );
  const responseStatus = response.status;
  const responseBody = (await response.json()) as RequestAccessResponse;
  if (responseStatus < 500) {
    return responseBody;
  } else {
    throw new Error(`Access request failed due to a technical error.`);
  }
}

async function createUser(
  jwt: string | undefined,
  username: string,
  email: string,
  roles: AuthorizedRole[],
  locale: string,
  sendEmail: boolean
): Promise<AllowAccessResponse> {
  if (!jwt || isExpired(jwt)) {
    throw new AuthTokenMissingOrExpiredError();
  }
  const response = await fetch(
    process.env.REACT_APP_SERVER_BASE_URL + `/admin/v1/users`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      mode: "cors",
      method: "POST",
      body: JSON.stringify({ username, email, roles, locale, sendEmail }),
    }
  );
  const responseStatus = response.status;
  const responseBody = (await response.json()) as AllowAccessResponse;
  if (responseStatus < 500) {
    return responseBody;
  } else {
    throw new Error(`Access request failed due to a technical error.`);
  }
}

async function get<T>(jwt: JWT, url: string): Promise<T | null> {
  if (!jwt) {
    throw new CodedError(ERROR_CODE_UNAUTHORIZED, "No JWT present.");
  }
  if (isExpired(jwt)) {
    throw new CodedError(ERROR_CODE_UNAUTHORIZED, "JWT expired.");
  }
  const response = await fetch(process.env.REACT_APP_SERVER_BASE_URL + url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (response.status === StatusCodes.UNAUTHORIZED) {
    // TODO this might not require a logout in all cases...
    throw new CodedError(
      ERROR_CODE_UNAUTHORIZED,
      "Server returned UNAUTHORIZED response."
    );
  }
  if (response.status >= 400) {
    return null;
  }
  return response.json();
}

async function mustGet<T>(jwt: JWT, url: string): Promise<T> {
  return get(jwt, url) as Promise<T>;
}

async function post<I, O>(jwt: JWT, url: string, body?: I): Promise<O> {
  // TODO check JWT before making the call
  const response = await fetch(process.env.REACT_APP_SERVER_BASE_URL + url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    mode: "cors",
    method: "POST",
    body: body && JSON.stringify(body),
  });
  return response.json() as O;
}

async function doDelete(jwt: JWT, url: string): Promise<void> {
  // TODO check JWT before making the call
  const response = await fetch(process.env.REACT_APP_SERVER_BASE_URL + url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    mode: "cors",
    method: "DELETE",
  });
  return response.json();
}

async function getContactInfos(jwt: JWT): Promise<ContactInfos> {
  return mustGet<ContactInfos>(jwt, "/home/contact");
}

interface GetPhotoAlbumsOptions {
  timsArchive?: boolean;
  tags?: string[];
}

async function listPhotoAlbums(
  jwt: JWT,
  { timsArchive, tags }: GetPhotoAlbumsOptions = {
    timsArchive: false,
    tags: [],
  }
): Promise<ListPhotoAlbumsResponseV3> {
  let url = "/photos/v3/albums";
  const searchParams = new URLSearchParams();
  if (timsArchive) {
    searchParams.append("timsArchive", "1");
  }
  tags?.forEach((tag) => searchParams.append("tags", tag));
  const query = searchParams.toString();
  if (query.length > 0) {
    url = `${url}?${query}`;
  }
  return mustGet<ListPhotoAlbumsResponseV3>(jwt, url);
}

async function getPhotoAlbum(
  jwt: JWT,
  albumYear: string,
  albumFolder: string
): Promise<OpenPhotoAlbum> {
  return mustGet<OpenPhotoAlbum>(
    jwt,
    `/photos/v2/albums/${albumYear}/${albumFolder}`
  );
}

async function getRecipes(jwt: JWT): Promise<Recipe[]> {
  return mustGet<Recipe[]>(jwt, "/recipes");
}

async function getRecipe(jwt: JWT, recipeKey: string): Promise<Recipe | null> {
  return get<Recipe>(jwt, `/recipes/${recipeKey}`);
}

async function getVideos(jwt: JWT): Promise<Video[]> {
  return mustGet<Video[]>(jwt, "/videos");
}

async function getPlaces(jwt: JWT): Promise<Place[]> {
  return mustGet<Place[]>(jwt, "/places");
}

async function getFeatures(jwt: JWT): Promise<Feature[]> {
  if (!jwt) {
    return [];
  }
  return mustGet<Feature[]>(jwt, "/features");
}

async function listUsers(jwt: JWT): Promise<User[]> {
  return mustGet<User[]>(jwt, "/admin/v1/users");
}

async function executeDatabaseMigrations(jwt: JWT): Promise<void> {
  return post(jwt, "/admin/v1/database/migrations");
}

async function listTodoLists(jwt: JWT): Promise<TodoList[]> {
  return mustGet<TodoList[]>(jwt, "/todos/lists");
}

async function createTodoList(jwt: JWT, title: string): Promise<void> {
  return post(jwt, "/todos/lists", { title });
}

async function createTodo(
  jwt: JWT,
  listId: string,
  summary: string
): Promise<void> {
  return post(jwt, `/todos/lists/${listId}/todos`, { summary });
}

async function deleteTodoList(jwt: JWT, listId: string): Promise<void> {
  return doDelete(jwt, `/todos/lists/${listId}`);
}

async function deleteTodo(
  jwt: JWT,
  listId: string,
  todoId: string
): Promise<void> {
  return doDelete(jwt, `/todos/lists/${listId}/todos/${todoId}`);
}

export default function useApi() {
  const { jwt } = useAuth();
  return {
    getPhotoAlbum: getPhotoAlbum.bind(null, jwt),
    getContactInfos: getContactInfos.bind(null, jwt),
    listPhotoAlbums: listPhotoAlbums.bind(null, jwt),
    getPlaces: getPlaces.bind(null, jwt),
    getRecipe: getRecipe.bind(null, jwt),
    getRecipes: getRecipes.bind(null, jwt),
    getVideos: getVideos.bind(null, jwt),
    getFeatures: getFeatures.bind(null, jwt),
    requestAccess,
    createUser: createUser.bind(null, jwt),
    createChallenge,
    attemptChallenge,
    listUsers: listUsers.bind(null, jwt),
    listTodoLists: listTodoLists.bind(null, jwt),
    createTodoList: createTodoList.bind(null, jwt),
    createTodo: createTodo.bind(null, jwt),
    deleteTodoList: deleteTodoList.bind(null, jwt),
    deleteTodo: deleteTodo.bind(null, jwt),
    executeDatabaseMigrations: executeDatabaseMigrations.bind(null, jwt),
  };
}
